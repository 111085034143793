<template>
  <v-layout class="mt-3" align-center justify-center>
    <Popup
      v-bind="confirmSelectionPopup"
      @confirm="rescheduleInterview"
      @cancel="showConfirm = false"
      @update:showDialog="showConfirm = false"
    />
    <Popup
      v-bind="instructionPopup"
      @confirm="showInstruction = false"
      @update:showDialog="showInstruction = false"
    />
    <v-flex xs12 sm8 md4 v-if="isBusy" class="text-center">
      <v-progress-circular indeterminate :color="color" size="100" />
    </v-flex>
    <Calendar
      v-if="isActive && !isBusy"
      :events="events"
      ref="cal"
      @intervalClick="click"
      :intervalStart="intervalStart"
      :intervalEnd="intervalEnd"
      :isBusy="isBusy"
      :color="color"
      locale="ja"
      defaultType="week"
      :height="800"
      :enableAdd="false"
      :enableFilter="false"
      :touch="true"
    />
    <v-flex xs12 sm8 md4 v-if="!isActive && !isBusy">
      <v-card>
        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">{{ title }}</h3>
            {{ subtitle }}
          </div>
        </v-card-title>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>会社名</v-list-item-title>
              <v-list-item-subtitle>{{ companyNameJa }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>応募者</v-list-item-title>
              <v-list-item-subtitle>{{ applicantName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>日付</v-list-item-title>
              <v-list-item-subtitle>{{ date }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>開始時間</v-list-item-title>
              <v-list-item-subtitle>{{ time }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { Calendar } from '@appsocially/timepassport'
import '@appsocially/timepassport/dist/timepassport.css'
import { generateRuleSlots } from '@appsocially/schedulerly-client/src/helpers/rule'
import { parseDateTimeString, toDate, toTime, formatDateTime } from '@appsocially/schedulerly-client/src/helpers/time'

import {
  startOfToday,
  startOfTomorrow,
  isAfter,
  getDate,
  getDay,
  getMonth,
  format,
  differenceInWeeks,
  min,
  startOfWeek, addMinutes, differenceInMinutes
} from 'date-fns'
import { getRescheduleInfo, rescheduleInterview } from '@/helpers/firebase-functions'
import Popup from '@/components/calendar/Popup'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    Calendar,
    Popup
  },
  props: {
    rescheduleToken: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      color: 'amber lighten-2',
      scheduledRules: null,
      scheduledSlots: [],
      showConfirm: false,
      showInstruction: false,
      selectedSlot: {},
      isBusy: true,
      isActive: false,
      companyNameJa: '',
      applicantName: '',
      date: '',
      time: '',
      pendingReschedule: false,
      title: '日時変更済みの面接',
      subtitle: 'この面接はすでに下記の日時に変更されています。再び日時を変更する場合は、最新のメールから日時変更の手続きをしてください。',
      hasSkipped: false
    }
  },
  computed: {
    generatedSlots () {
      let generatedSlots = []

      if (!this.scheduledRules) {
        return generatedSlots
      }
      let startDate = startOfTomorrow()

      if (this.$refs.cal) {
        const { start: { date: calendarStart } } = this.$refs.cal
        const calendarStartDate = parseDateTimeString(`${calendarStart} 00:00`)
        if (isAfter(calendarStartDate, startDate)) {
          startDate = calendarStartDate
        }
      }
      return generateRuleSlots({
        startDate,
        rules: this.scheduledRules,
        numberOfDays: 31
      })
    },
    events () {
      const events = this.scheduledSlots
        .filter(slot => (slot.attendees || []).length < slot.maxAttendees)
        .map((event) => {
          const { id, from, to } = event
          return {
            id,
            start: formatDateTime(from),
            end: formatDateTime(to),
            status: 'open',
            color: this.color
          }
        })
        .concat(this.generatedSlots.map(slot => {
          const { startTime, duration } = slot
          const start = formatDateTime(startTime)
          const end = formatDateTime(addMinutes(startTime, duration))
          return {
            start,
            end,
            status: 'open',
            color: this.color
          }
        }))

      return events
    },
    intervalEnd () {
      let end = 0
      this.events.forEach(event => {
        const hour = parseDateTimeString(event.end).getHours()
        if (hour > end) {
          end = hour
        }
      })
      return end + 1
    },
    intervalStart () {
      let start = 24
      this.events.forEach(event => {
        const hour = parseDateTimeString(event.start).getHours()
        if (hour < start) {
          start = hour
        }
      })
      return start
    },
    confirmSelectionPopup () {
      return {
        showDialog: this.showConfirm,
        title: this.selectedSlot.message,
        confirm: 'はい',
        cancel: 'いいえ',
        color: this.styleColor,
        confirmLoading: this.pendingReschedule
      }
    },
    instructionPopup () {
      return {
        showDialog: this.showInstruction,
        title: 'ご希望の面接日時を黄色のスケジュールから選んでください。',
        confirm: 'はい',
        cancel: '',
        color: this.styleColor
      }
    }
  },
  methods: {
    click ({ event, action }) {
      if (action === 'closed') {
        const { status, id, start, end } = event
        if (status === 'open') {
          const startTime = parseDateTimeString(start)

          this.selectedSlot = {
            id,
            start,
            end,
            message: `面接日時を${getMonth(startTime) + 1}月${getDate(startTime)}日（${this.getJapaneseDay(getDay(startTime))}）${format(startTime, 'HH:mm')}に変更してよろしいですか？`
          }

          this.showConfirm = true
        }
      }
    },
    getJapaneseDay (day) {
      const dayMapping = {
        0: '日',
        1: '月',
        2: '火',
        3: '水',
        4: '木',
        5: '金',
        6: '土'
      }

      return dayMapping[day]
    },
    rescheduleInterview () {
      const { id, start, end } = this.selectedSlot

      const startTime = parseDateTimeString(start)
      const endTime = parseDateTimeString(end)
      const duration = differenceInMinutes(endTime, startTime)

      this.pendingReschedule = true
      rescheduleInterview({
        rescheduleToken: this.rescheduleToken,
        slot: {
          duration,
          isoDate: toDate(startTime),
          startTime: toTime(startTime),
          timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
          schedulerlySlotId: id }
      }).then(result => {
        this.isActive = false
        this.date = toDate(startTime)
        this.time = toTime(startTime)
        this.title = '日時変更の完了'
        this.subtitle = '下記のとおり面接日時が変更されました。ありがとうございます。'
        this.showConfirm = false
        this.pendingReschedule = false
      })
    },
    skipToEarliestWeek () {
      const earliestDateWeekStart = startOfWeek(min(this.events.map(({ start }) => parseDateTimeString(start))))
      const weekStart = startOfWeek(startOfToday())
      const weekDifference = differenceInWeeks(earliestDateWeekStart, weekStart)
      const calendarComponent = this.$refs['cal']
      if (calendarComponent) {
        const { calendar } = calendarComponent.$refs
        if (calendar) {
          calendar.next(weekDifference)
          this.hasSkipped = true
        }
      }
    }
  },
  created () {
    getRescheduleInfo({ rescheduleToken: this.rescheduleToken }).then(result => {
      const { data: { slots, rules, interview } } = result
      this.scheduledSlots = slots
        .map(slot => ({
          ...slot,
          from: new Date(slot.from),
          to: new Date(slot.to)
        }))
        .filter(slot => slot.attendees.length < slot.maxAttendees)

      this.scheduledRules = {
        ...rules,
        ignoredStartTimes: rules.ignoredStartTimes.map(time => ({
          ...time,
          startTime: new Date(time.startTime)
        }))
      }
      const { isActive, applicantName, companyNameJa, isoDate, startTime } = interview
      this.isActive = isActive
      if (isActive) {
        this.showInstruction = true
      }
      this.applicantName = applicantName
      this.companyNameJa = companyNameJa
      this.date = isoDate
      this.time = startTime
      this.isBusy = false
    })
  },
  updated () {
    this.$nextTick(function () {
      if (!this.hasSkipped) {
        this.skipToEarliestWeek()
      }
    })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_APPLICANT_RESCHEDULE)
  }
}
</script>
